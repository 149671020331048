import React, { useEffect, useState } from "react";
import { Route, RouteProps, useLocation, useSearchParams, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import qs from "query-string";
import axios from "axios";

const BridgeAuthRoute = (props) => {
    const location = useLocation()
    const history = useHistory()

    const fallbackLoginRedirect = () =>
        window.location.replace(`https://www.castingworkbook.com/error/401.html`);

    const fallbackSSORedirect = () =>
        window.location.replace(`${process.env.REACT_APP_API}/auth/jwt/sso?returnUrl=${window.location.href}`);

    const [isLoading, setIsLoading] = useState(true);
    const [authenticatedUser, setAuthenticatedUser] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const k = queryParams.get('k');

        (async function attemptAuth() {
            let protocol = process.env.REACT_APP_API

            const authViaParams = async () => {
                try {
                    let url = `${protocol}/auth/jwt/bridge`;
                    const webSessionRes = await axios(url, {
                        method: 'post',
                        headers: { CWB: '1' },
                        data: { appKey: 'cwb-react', k: k },
                        withCredentials: true
                    });
                    if (webSessionRes.status === 200) {
                        const accessToken = webSessionRes.data.accessToken;
                        localStorage.setItem("CWB_TOKEN", accessToken);
                        queryParams.delete('k')
                        history.replace({
                            search: queryParams.toString(),
                        })
                        setAuthenticatedUser(webSessionRes.data);
                        setIsLoading(false);
                    } else {
                        console.log(webSessionRes)
                        fallbackLoginRedirect();
                    }
                } catch (error2) {
                    console.log(error2)
                    fallbackLoginRedirect();
                }
            }

            if (k) {
                await authViaParams();
                return;
            }

            try {
                let url = `${protocol}/auth/jwt/seamless`;
                const seamlessRes = await axios(url, {
                    method: 'post',
                    headers: { CWB: '1' },
                    data: { appKey: 'cwb-react' },
                    withCredentials: true
                });
                if (seamlessRes.status === 200) {
                    const accessToken = seamlessRes.data.accessToken;
                    localStorage.setItem("CWB_TOKEN", accessToken);
                    setAuthenticatedUser(seamlessRes.data);
                    setIsLoading(false);
                } else {
                    console.log(seamlessRes)
                    fallbackLoginRedirect();
                }
            } catch (error) {
                console.error(error);
                if (!k) {
                    fallbackSSORedirect();
                    return;
                }
                authViaParams();
            }
        })();
    }, []);

    // const showHeader = !params.hs;
    const showHeader = false;
    return (
        <Wrapper showHeader={showHeader}>
            {isLoading && !authenticatedUser ? (
                //   <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
                <p> Loading..</p>
            ) : (
                <Route component={props.Component} {...props} authenticatedUser={authenticatedUser} />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  padding-top: 60px;

  ${(props) =>
        !props.showHeader &&
        css`
      padding-top: 0;
    `}

  @media all and (max-width: 520px) {
    flex-direction: column;
  }
`;

export default BridgeAuthRoute;
