import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import ByDeviceWithRegion from "./SignUps/byDeviceWithRegion"
import ByActorTypeWithRegion from "./SignUps/byActorTypeWithRegion"
import BySourceWithRegion from "./SignUps/bySourceWithRegion"

class SignUpsWithRegionExplorer extends Component {
    render() {
        return <Container>
            <h1>
                All Sign Ups by Region explorer
            </h1>           
            <h2>Weekly by Source with Region</h2>
            <BySourceWithRegion />
            <hr />

            <br/>
            <h2>Weekly by Actor Type with Region</h2>
            <ByActorTypeWithRegion />
            <hr />

        </Container>
    }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


export default SignUpsWithRegionExplorer;