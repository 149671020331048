import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import UpgradesByDevice from "./Upgrades/upgradesByDevice"
import UpgradesByBreakdown from "./Upgrades/upgradesByBreakdown"
import UpgradesByLabs from "./Upgrades/upgradesByLabs"
import UpgradesBySource from "./Upgrades/upgradesBySource"

class UpgradesExplorer extends Component {
    render() {
        return <Container>
            <h1>
                Upgrades to PRO explorer
            </h1>

            <h2>Weekly by Breakdown</h2>
            <UpgradesByBreakdown />
            <hr />

            {/* <br/>
            <h2>Weekly by Device</h2>
            <UpgradesByDevice />
            <hr /> */}

            <br/>
            <h2>Weekly by Source</h2>
            <UpgradesBySource />
            <hr />

        </Container>
    }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


export default UpgradesExplorer;