import React, { Component } from "react";
import axios from "axios";
import { Table } from 'antd';
import styled from "styled-components";

class MobileStatus extends Component{
  state = {
    data:[]
  }
  async componentDidMount(){
    const token = localStorage.getItem("CWB_TOKEN");
    if(token){
      await this.fetchMobileStatus();
    }
  }
  fetchMobileStatus = async () => {
    const protocol = process.env.REACT_APP_NODEBACKEND;
    const token = localStorage.getItem("CWB_TOKEN");
    const url = `${protocol}/mobile_status`;
    const AuthStr = 'Bearer '.concat(token);
    try {
      const res = await axios.get(url, {headers:{Authorization:AuthStr}});
      if(res.status === 200){
        this.setState({
          data:res.data.result
        })
      }
    }catch(err){
      console.log(err);
    }
  }
  render(){
    const {data} = this.state;
    const columns = [
      {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
      },
      {
        title: 'IsMobile',
        dataIndex: 'IsMobile',
        key: 'IsMobile',
        render: IsMobile => {
          if(IsMobile){
            return "true"
          }else {
            return "false"
          }
        }
      },
      {
        title: 'Qty',
        dataIndex: 'Qty',
        key: 'Qty',
      }
    ]
    return <Container>
      <h1>
        MobileStatus
      </h1>
      <Table columns={columns} dataSource={data} />
    </Container>
  }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
`;


export default MobileStatus;