import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import MobileStatus from "./Component/mobileStatus";
import MobileStatusExplorer from "./Component/mobileStatusExplorer";
import Guide from "./Component/guide";
import BridgeAuthRoute from "./bridgeAuthRoutes";
import SignUpsExplorer from "./Component/signUpsExplorer";
import SignUpsWithRegionExplorer from "./Component/signUpsWithRegionExplorer";
import LabsSignUpsExplorer from "./Component/labsSignUpsExplorer";
import JobsExplorer from "./Component/jobsExplorer";
import PaidExplorer from "./Component/paidExplorer";
import UpgradesExplorer from "./Component/upgradesExplorer";
import ConversationExplorer from "./Component/conversationsExplorer";
import ConversionsCombinedExplorer from "./Component/conversionsCombinedExplorer";
import TotalConversionsExplorer from "./Component/totalConversionsExplorer";

class Router extends Component{

 render(){
   return <BrowserRouter>
   <Switch>
     <BridgeAuthRoute path="/guide" Component={Guide}/>
     <BridgeAuthRoute path="/signups-explorer" Component={SignUpsExplorer}/>
     <BridgeAuthRoute path="/signupsByRegion-explorer" Component={SignUpsWithRegionExplorer}/>
     <BridgeAuthRoute path="/signupsLabs-explorer" Component={LabsSignUpsExplorer}/>     
     <BridgeAuthRoute path="/paid-explorer" Component={PaidExplorer}/>
     <BridgeAuthRoute path="/jobs-explorer" Component={JobsExplorer}/>
     <BridgeAuthRoute path="/mobile-stats" Component={MobileStatus}/>
     <BridgeAuthRoute path="/mobile-stats-explorer"  Component={MobileStatusExplorer}/>
     <BridgeAuthRoute path="/upgrades-explorer"  Component={UpgradesExplorer}/>
     <BridgeAuthRoute path="/converations-explorer"  Component={ConversationExplorer}/>
     <BridgeAuthRoute path="/conversions-combined-explorer"  Component={ConversionsCombinedExplorer}/>
     <BridgeAuthRoute path="/total-conversions"  Component={TotalConversionsExplorer}/>

     <Route exact path="/">
       <Redirect to="/guide" />
     </Route>

     <Route
          render={() => {
            window.location.href = "https://www.castingworkbook.com/404.html";
            return null;
          }}
        />
   </Switch>
   </BrowserRouter>
 }
}

export default Router;