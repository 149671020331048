import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import ByDevice from "./SignUps/byDevice"
import ByBreakdown from "./SignUps/byBreakdown"
import ByLabs from "./SignUps/byLabs"
import ByActorType from "./SignUps/byActorType"
import BySource from "./SignUps/bySource"

class SignUpsExplorer extends Component {
    render() {
        return <Container>
            <h1>
                All Sign Ups explorer
            </h1>

            <h2>Weekly by Breakdown</h2>
            <ByBreakdown />
            <hr />

            {/* <br/>
            <h2>Weekly by Device</h2>
            <ByDevice />
            <hr /> */}

            <br/>
            <h2>Weekly by Source</h2>
            <BySource />
            <hr />

            <br/>
        
            <h2>Weekly by Actor Type</h2>
            <ByActorType />
            <hr />

        </Container>
    }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


export default SignUpsExplorer;