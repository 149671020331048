import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import PaidByLabs from "./SignUps/paidByLabs"
import DataExplorerContainer from "./DataExplorer/dataExplorerContainer";

const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
  .hide {
	display: none;
  }
  .active {
	display: block;
  }
`;

export default function SignUpsExplorer() {
  const [searchBy, setSearchBy] = useState("week");
  const currentDate = searchBy === "dd" ? "Daily" : "Weekly";
  const inverseDate = searchBy !== "dd" ? "Daily" : "Weekly";
  const handleChange = () => {
	setSearchBy(searchBy === "dd" ? "week" : "dd");
  }

  const data = [{
		title: "By Breakdown",
		query: "byBreakdown",
		explorer: "paidByBreakdown",
		requestType: "paidExplorer"
	},
	// {
	// 	title: "By Device",
	// 	query: "byDevice",
	// 	explorer: "paidByDevice",
	// 	requestType: "paidExplorer"
	// },
	{
		title: "by Source",
		query: "bySource",
		explorer: "paidBySource",
		requestType: "paidExplorer"
	},
	// {
	// 	title: "By Source With Region",
	// 	query: "bySourceWithRegion",
	// 	explorer: "paidByBreakdownWithRegion",
	// 	requestType: "paidExplorer"
	// }
]

  
  return <Container>
    <h1>
        Only paid Sign Ups explorer
    </h1>
	<button onClick={()=> handleChange()}>Change to {inverseDate}</button>
	<br/>
	<br/>
	<div className={searchBy === "week" ? "active" : "hide"}>
		{data.map((item, idx) => {
			return (
				<>
						<h2>{currentDate} {item.title}</h2>
						<DataExplorerContainer
							key={idx + searchBy}
							date={"week"}			
							query={item.query}
							explorer={item.explorer}
							requestType={item.requestType}
						/>
						<hr />
				</>
			)
		})}
	</div>
	
	<div className={searchBy === "dd" ? "active" : "hide"}>
		{data.map((item, idx) => {
			return (
				<>
					<h2>{currentDate} {item.title}</h2>
					<DataExplorerContainer
						key={idx + searchBy}
						date={"dd"}					
						query={item.query}
						explorer={item.explorer}
						requestType={item.requestType}
					/>
					<hr />
				</>
			)
		})}
	</div>

    </Container>
};