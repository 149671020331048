import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import { fetchMobileStatusExplorer } from "../api/dashStats"

class MobileStatusExplorer extends Component{
  state = {
    data:null
  }
  async componentDidMount(){
    const token = localStorage.getItem("CWB_TOKEN");
    if(token){
      await this.fetchMobileStatus();
    }
  }
  fetchMobileStatus = async () => {
    try {
      const res = await fetchMobileStatusExplorer();
      if(res.status === 200){
        this.setState({
          data:res.data.result
        })
      }
    }catch(err){
      console.log(err);
    }
  }
  render(){
    const {data} = this.state;
    if (!data){
      return <Container>Loading..</Container>
    }
    return <Container>
      <h1>
      Sign Ups - Web vs Mobile 
      </h1>
      {
       <DataExplorer data={data} />
      }
    </Container>
  }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


export default MobileStatusExplorer;