import { useQuery } from 'react-query'
import React, { Component, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { fetchPaidExplorer, fetchConversationsExplorer } from "../../api/dashStats"
import CwbDataExplorer from './cwbDataExplorer';


function DataExplorerContainer(props) {
  const { date, query, explorer, requestType, ignorePeriod, filter, skip } = props;
  const request = {
    paidExplorer: fetchPaidExplorer,
    conversationExplorer: fetchConversationsExplorer
  }

  async function fetch() {
    const target = request[requestType];
    const { data } = await target(query, date, filter)

    if (data.result.data)
      return data.result;

    return {...data.result, data: []};
  }

  const period = useMemo(() => {
    return ignorePeriod ? (date || "week") : ''
  }, [ignorePeriod, date]);

  const { data, error, isError, isLoading, isFetching } = useQuery([explorer, period, filter, skip], fetch, { 
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !skip
  });
  const dataProps = { data, error, isError, isLoading: (isLoading || isFetching), date, query, explorer };
  return <CwbDataExplorer  {...dataProps} />
}
export default DataExplorerContainer;