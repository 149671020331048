import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import DataExplorerContainer from "./DataExplorer/dataExplorerContainer";

const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;

export default function ConversionsCombinedExplorer() {
  const data = [{
		title: "By Source",
		query: "byPlatformCombined",
		explorer: "conversationsByPlatformCombined",
		requestType: "conversationExplorer"
	},
	{
		title: "By Breakdown",
		query: "byBreakdownCombined",
		explorer: "conversationsByBreakdownCombined",
		requestType: "conversationExplorer"
	},
	{
		title: "By Source and Breakdown",
		query: "byPlatformAndBreakdownCombined",
		explorer: "conversationsByPlatformAndBreakdownCombined",
		requestType: "conversationExplorer"
	}
]
  
  return (
  	<Container>
		<h1>
			Conversions Combined
		</h1>
		<div>
		{data.map((item, idx) => {
			return (
			<div key={(idx + item.query)}>
				<h2>{(item.ignorePeriod ? "" : "Daily")} {item.title}</h2>
				<DataExplorerContainer
				key={(idx + item.query)}
				date="dd"		
				query={item.query}
				explorer={item.explorer}
				requestType={item.requestType}
				ignorePeriod={item.ignorePeriod || false}
				/>
				<hr />
			</div>
			)
		})}
		</div>

    </Container>)
};