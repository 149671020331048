import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import BySource from "./Jobs/bySource"
import ByBreakdown from "./Jobs/byBreakdown"
import ByRegion from "./Jobs/byRegion"
import TotalByBreakdown from "./Jobs/totalByBreakdown"

class JobsExplorer extends Component{
  render(){
    return <Container>
      <h1>
      Jobs explorer (PAYG ONLY)
      </h1>

      <h2> Totals by Breakdown</h2>
      <TotalByBreakdown/>
      <hr/>

      <br/>
      <h2>Weekly by Breakdown</h2>
      <ByBreakdown/>
      <hr/>

      <h2>Weekly by Source</h2>
      <BySource/>
      <hr/>

      <br/>
      <h2>Weekly by Region</h2>
      <ByRegion/> 
      <hr/>

    </Container>
  }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


export default JobsExplorer;