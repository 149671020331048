import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import DataExplorer from "@nteract/data-explorer";
import ByLabs from "./SignUps/byLabs"
import PaidByLabs from "./SignUps/paidByLabs"
import UpgradesByLabs from "./Upgrades/upgradesByLabs"


class LabsSignUpsExplorer extends Component {
    render() {
        return <Container>
            <h1>
                All CWB Labs metrics
            </h1>

            <h2>Weekly Total Sign-Ups via CWB Labs</h2>
            <ByLabs />
            <hr />

            <br/>
            <h2>Weekly Paid Only via CWB Labs</h2>
            <PaidByLabs />
            <hr />

            <h2>
                Upgrades to PRO explorer
            </h2>
            
            <h2>Weekly Upgrades via CWB Labs</h2>
            <UpgradesByLabs />
            <hr />


        </Container>
    }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


export default LabsSignUpsExplorer;