import { useQuery } from 'react-query'
import React, { Component, useRef, useEffect, useMemo, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { DataExplorer, Toolbar, Viz } from "@nteract/data-explorer";
import csvDownload from 'json-to-csv-export'

const CwbDataExplorer = (props) => {
    const { isLoading, isError, error, data, exportFileName } = { ...props };
    const prevDataRef = useRef();

    useEffect(() => {
        if (data) {
            if (data.data) {
                if (data !== prevDataRef.current) {
                    prevDataRef.current = data;
                }
            }
        }
    }, [data]);

    const [table, setTable] = useState(null);
    const [ready, setReady] = useState(true);
    
    useEffect(() => {
        // setReady(false);
        if (data && data.data) {
            setTable(data);
        } else {            
            if (data) {
                setTable({ ...data, data: [] });
            } else if (prevDataRef.current) {
                setTable(prevDataRef.current);
            } else {
                setTable(null);
            }
        }
    }, [data]);

    // useLayoutEffect(() => {
    //     setReady(true);
    // },[table])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div>Error! {error.message}</div>;
    }

    if (isLoading || !ready) {
        return <div>Loading...</div>
    }
    if (isError) {
        return <div>Error! {error.message}</div>
    }

    if (!table) {
        return (
            <div>
                <i> No results yet</i>
            </div>
        )
    }

    return (
        <div>
            {
                <DataExplorer data={(data && data.data) ? data : table}>

                    <Viz />

                    <Container>

                        &nbsp;
                        |
                        &nbsp;

                        <ExportButton onClick={() => csvDownload(data.data, (exportFileName || `${new Date().toISOString().split('T')[0]}-metrics.csv`))}>Export to CSV</ExportButton>
                    </Container>

                    <Toolbar />
                </DataExplorer>
            }
        </div>
    )
}

const Container = styled.div`
    position: absolute;
    margin-left: 95px;
`;

const ExportButton = styled.button`
    margin-left: 5px;
`

export default CwbDataExplorer