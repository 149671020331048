import { useQuery } from 'react-query'
import React, { Component } from "react";
import styled from "styled-components";
import { fetchSignUpsExplorer } from "../../api/dashStats"
import { CwbDataExplorer } from "../DataExplorer";

async function fetch() {
    const { data } = await fetchSignUpsExplorer('byLabs')
    return data.result;
}
function ByLabs() {
    const { data, error, isError, isLoading } = useQuery('signUpsByLabs', fetch)
    const dataProps = { data, error, isError, isLoading };
    return <CwbDataExplorer  {...dataProps } />
}
export default ByLabs