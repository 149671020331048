import React, { Component, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import DataExplorerContainer from "./DataExplorer/dataExplorerContainer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;


const DatePickerContainer = styled.div`
	font-size:14px;
	display: inline-block;
	position: relative;
    bottom: 0.25em;
	.react-datepicker__month-container {
		width: 220px;
	}
	.react-datepicker-popper {
		z-index: 999;
	}
	.react-datepicker__calendar-icon {
		font-size: 34px;
    	top: 2px;
	}
	.react-datepicker__view-calendar-icon input {
		padding-left: 30px;
	}
	
`
const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: -0.5em;
	margin-left: 1em;
	margin-bottom: 0.5em;
	column-gap: 1em;

`

const FieldContainer = styled.div`
	display:flex;
	flex-direction:column;

	label {
		margin-bottom:0.25em;
		margin-left: 0.1em;
	}
	select {
		border: 2px solid black;
		height: 36px;
		margin:0;
		position:relative;
		top: -3px;
		width: 180px;
		padding-left:4px;
	}
`

export default function TotalConversionsExplorer() {
	const data = [{
		title: "Sorted by Breakdown",
		query: "byBreakdownCombinedAndSorted",
		explorer: "conversationsbyBreakdownAndSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	},
	{
		title: "Sorted by Source",
		query: "bySourceCombinedAndSorted",
		explorer: "conversationsbySourceAndSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	},
	{
		title: "Sorted by Source and Breakdown",
		query: "bySourceAndByBreakdownCombinedAndSorted",
		explorer: "conversationsbySourceAndByBreakdownSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	}]

	const [dateValue, setDateValue] = useState(new Date());

	const [startDate, setStartDate] = useState(dateValue);
	const [endDate, setEndDate] = useState(new Date());

	const [disableCall, setDisableCall] = useState(true);

	useEffect(() => {
		setStartDate(dateValue);
	}, [dateValue]);

	const [customLocation, setCustomLocation] = useState(null);
	const [filterObj, setFilterObj] = useState(null);

	useEffect(() => {
		// console.log([customLocation, startDate, endDate]);

		let temp;
		if (endDate) {
			temp = (customLocation || startDate || endDate) ? { startDate, endDate, location: customLocation } : null
		} else {
			// old filter
			temp = (dateValue || customLocation) ? { yearMonth: dateValue, location: customLocation } : null
		}

		setFilterObj(temp);

		if (customLocation) {
			setDisableCall(false);
		} else {
			setDisableCall(true);
		}

	}, [customLocation, startDate, endDate]);

	const queryFilter = useMemo(() => {
		// console.log(filterObj);
		return filterObj;
	}, [JSON.stringify(filterObj || {})])

	return (
		<Container>
			<h1>
				Total Conversions
			</h1>
			<FilterContainer>

				<FieldContainer>
					<label htmlFor="date">Start (Year/Month):</label>

					<DatePickerContainer>
						<DatePicker autoComplete="off" isClearable={true} id="date" name="date" maxDate={(endDate ? endDate : new Date())} showIcon selected={dateValue} onChange={(date) => setDateValue(date)} dateFormat="yyyy/MM"
							showMonthYearPicker />
					</DatePickerContainer>
				</FieldContainer>

				<FieldContainer>
					<label htmlFor="date">End (Year/Month):</label>

					<DatePickerContainer>
						<DatePicker autoComplete="off" isClearable={true} id="endDate" name="endDate" minDate={startDate} maxDate={new Date()} showIcon selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yyyy/MM"
							showMonthYearPicker />
					</DatePickerContainer>
				</FieldContainer>
				<FieldContainer>
					<label htmlFor="customLocation">Actor Residence:</label>
					<select id="customLocation" name="customLocation" onChange={(ev) => {
						ev.preventDefault();
						// console.log(ev.target.value);
						setCustomLocation(ev.target.value);
					}}>
						<option />
						<option value={'ALL'}>ALL</option>
						<option value={'CA'}>Canada</option>
						<option value={'US'}>US</option>
						<option value={'ES'}>ES (€)</option>
						<option value={'LATAM'}>LATAM</option>
						<option value={'ROW'}>RoW</option>
					</select>
				</FieldContainer>
			</FilterContainer>

			<div>
				{data.map((item, idx) => {
					return (
						<div key={(idx + item.query)}>
							<h2>{(item.ignorePeriod ? "" : "Daily")} {item.title}</h2>
							<DataExplorerContainer
								key={(idx + item.query)}
								date="dd"
								query={item.query}
								explorer={item.explorer}
								requestType={item.requestType}
								ignorePeriod={item.ignorePeriod || false}
								skip={disableCall}
								filter={queryFilter}
							/>
							<hr />
						</div>
					)
				})}
			</div>

		</Container>)
};
