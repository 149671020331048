import { useQuery } from 'react-query'
import React, { Component } from "react";
import styled from "styled-components";
import { fetchSignUpsExplorer } from "../../api/dashStats"
import { CwbDataExplorer } from "../DataExplorer";

async function fetch() {
    const { data } = await fetchSignUpsExplorer('byActorTypeWithRegion')
    return data.result;
}
function ByActorTypeWithRegion() {
    const { data, error, isError, isLoading } = useQuery('signUpsByActorTypeWithRegion', fetch)
    const dataProps = { data, error, isError, isLoading };
    return <CwbDataExplorer  {...dataProps } />
}
export default ByActorTypeWithRegion